import React from 'react';
import './skilltools1702740408.css'; // Import your custom styles here

const Component7: React.FC = () => {
    return (
        <>
            <head>
                <title>SkillTools</title>
                <meta
                    name="description"
                    content="ams. Gain the skills and knowledge needed to thrive in today's competitive workplace. Corporate Training Solutions: SkillTools partners with businesses to provide tailored training solutions that enhance the skill set of their workforce. Invest in your team's growth with our corporate training programs. Join SkillTools Today: Embark on a journey of continuous learning and growth with SkillTools. Whether you're an individual looking to upskill or a company seeking comprehensive training solutions, SkillTools is your partner in the pursuit of excellence. Unlock Your Potential with SkillTools."
                />
                <meta name="author" content="Content Bot" />
                <meta name="charset" content="utf-8" />
                <link
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"
                    rel="stylesheet"
                    integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
                    crossOrigin="anonymous"
                />
                <link href='skilltools1702740408.css' rel='stylesheet' />
                <link
                    rel='shortcut icon'
                    type='image/jpg'
                    href='https://www.gravatar.com/avatar/074d6be3b9fb54023b4ed3c6ed2ea2ad?d=identicon&f=y'
                />
            </head>
            <body>
            <div className="navigation shadow-sm position-relative">
                <div className="row px-4">
                    <div className="col-12 col-md-6 p-4">
                        <img
                            src="https://www.gravatar.com/avatar/074d6be3b9fb54023b4ed3c6ed2ea2ad?d=identicon&amp;f=y"
                            alt="Logo"
                        />
                        <div data-name="brand" className="logo">
                            SkillTools
                        </div>
                    </div>

                </div>
            </div>
            <div className="header-section bg-light">
                <div className="row">
                    <div className="col-12 order-2 col-md-6 order-md-2">
                        <div className="w-50 start-50 top-50 position-relative translate-middle py-4">
                            <h1 data-name="title">
                                Unlock Your Potential and Take Your Professional Growth to New Heights with Skilltools
                            </h1>
                            <p data-name="copy">
                                With our comprehensive collection of courses and workshops, professional development resources, and tailored corporate training solutions, SkillTools provides the perfect platform to help you and your team take your career advancement to the next level.
                            </p>
                        </div>
                    </div>

                    <div className="col-12 order-1 col-md-6 order-md-2">
                        <img
                            src="https://source.unsplash.com/random/1920x1080?"
                            alt="Unlock Your Potential and Take Your Professional Growth to New Heights with Skilltools"
                            title="Unlock Your Potential and Take Your Professional Growth to New Heights with Skilltools"
                            className="init"
                        />
                    </div>
                </div>
            </div>
            <div className="sub-header-section py-4">
                <div className="row">
                    <div className="col-12 col-md-6 py-4">
                        <div className="row">
                            <div className="col-8 m-auto card border-0 shadow p-0 overflow-hidden">
                                <img
                                    src="https://source.unsplash.com/random/1920x1080?"
                                    alt="Expert Trainers, Groundbreaking Resources, Tailored Corporate Solutions"
                                    title="Expert Trainers, Groundbreaking Resources, Tailored Corporate Solutions"
                                    className="init"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="w-50 start-50 top-50 position-relative translate-middle">
                            <h1 data-name="title">
                                Expert Trainers, Groundbreaking Resources, Tailored Corporate Solutions
                            </h1>
                            <p data-name="copy">
                                With the guidance of our expert trainers, the world's best e-learning platform, and tailored corporate training solutions, SkillTools helps teams build the skills and knowledge needed to thrive in today's competitive workplace.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features bg-light py-4">
                <div className="row py-4 mb-2">
                    <div className="col-12 text-center">
                        <h2>Features</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row item-container row-cols-1 row-cols-md-3">
                        <div className="feature col">
                            <div className="row text-center py-2">
                                <img
                                    src="https://source.unsplash.com/random/1920x1080?"
                                    alt="Comprehensive Courses"
                                    title="Comprehensive Courses"
                                    className="init"
                                />
                                <h3 data-name="title" className="col-12 mt-4 mb-2">
                                    Comprehensive Courses
                                </h3>
                                <p data-name="desc" className="col-12">
                                    Upgrade your professional skills with our collection of comprehensive online courses and workshops.
                                </p>
                            </div>
                        </div>
                        <div className="feature col">
                            <div className="row text-center py-2">
                                <img
                                    src="https://source.unsplash.com/random/1920x1080?"
                                    alt="Career Advancement Resources"
                                    title="Career Advancement Resources"
                                    className="init"
                                />
                                <h3 data-name="title" className="col-12 mt-4 mb-2">
                                    Career Advancement Resources
                                </h3>
                                <p data-name="desc" className="col-12">
                                    Stay up to date with the latest trends in your industry and leverage our online resources for career advancement.
                                </p>
                            </div>
                        </div>
                        <div className="feature col">
                            <div className="row text-center py-2">
                                <img
                                    src="https://source.unsplash.com/random/1920x1080?"
                                    alt="Corporate Training Solutions"
                                    title="Corporate Training Solutions"
                                    className="init"
                                />
                                <h3 data-name="title" className="col-12 mt-4 mb-2">
                                    Corporate Training Solutions
                                </h3>
                                <p data-name="desc" className="col-12">
                                    Invest in your team's growth with our tailored corporate training solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="benefits py-4">
                <div className="container py-4 mt-4 mb-4">
                    <div className="row item-container row-cols-1 row-cols-md-3">
                        <div className="benefit col">
                            <div className="row text-center py-2">
                                <div className="col-12">
                                    <div className="icon-wrap">
                                        <img
                                            className="icon init"
                                            src="https://www.gravatar.com/avatar/fe61a46b51c966716db9aa08ef931483?d=identicon&f=y"
                                            alt="Online Learning"
                                            title="Online Learning"
                                        />
                                    </div>
                                </div>
                                <h2 data-name="title" className="col-12">
                                    Online Learning
                                </h2>
                                <p data-name="desc" className="col-12">
                                    Enhance your skill set with our online learning platform and access the resources needed to take your training to the next level.
                                </p>
                            </div>
                        </div>
                        <div className="benefit col">
                            <div className="row text-center py-2">
                                <div className="col-12">
                                    <div className="icon-wrap">
                                        <img
                                            className="icon init"
                                            src="https://www.gravatar.com/avatar/a4fdb702a4be109522bd0ba4a9b6414b?d=identicon&f=y"
                                            alt="Expert Instructors"
                                            title="Expert Instructors"
                                        />
                                    </div>
                                </div>
                                <h2 data-name="title" className="col-12">
                                    Expert Instructors
                                </h2>
                                <p data-name="desc" className="col-12">
                                    Reap the rewards of studying with experienced and knowledgeable experts who care about your success.
                                </p>
                            </div>
                        </div>
                        <div className="benefit col">
                            <div className="row text-center py-2">
                                <div className="col-12">
                                    <div className="icon-wrap">
                                        <img
                                            className="icon init"
                                            src="https://www.gravatar.com/avatar/b80f2f3a165094ea8f35d61296f4282d?d=identicon&f=y"
                                            alt="Professional Growth"
                                            title="Professional Growth"
                                        />
                                    </div>
                                </div>
                                <h2 data-name="title" className="col-12">
                                    Professional Growth
                                </h2>
                                <p data-name="desc" className="col-12">
                                    Invest in yourself and your team's professional growth and unlock your potential to reach new heights.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer bg-dark text-light">
                <div className="row px-4 container m-auto">
                    <div className="col-12 p-4 text-center">
                        Copyright <span data-name="brand" className="copyright">SkillTools</span> <span data-name="year">2023</span>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            </body>
        </>
    );
};

export default Component7;
