import React, { useState, useEffect, useRef } from 'react';
import './Slideshow.css'; // Your CSS file

const slides = [
  {
    img: '/media/img/1.png',
    caption: 'Welcome to AI Integration',
    text: 'Welcome, everyone, to this journey into the future of technology and humanity. AI Integration isn\'t just a topic; it\'s a revolution, a paradigm shift that is redefining every aspect of our lives. As we embark on this journey today, I invite you to open your minds to the limitless possibilities that AI presents. Imagine a world where each task is optimized, where every challenge is met with intelligent, data-driven solutions. That world isn\'t far away; in fact, it\'s unfolding right before our eyes.',
    audio: '/media/audio/ai/1.wav'
  },
  {
    img: '/media/img/2.png',
    caption: 'Understanding AI',
    text: 'Let\'s start at the core. Understanding AI is fundamental. It\'s not just about algorithms and computing power; it\'s about a new language of problem-solving. Machine Learning, Deep Learning, Neural Networks — these aren\'t just buzzwords; they are the building blocks of a new era. Imagine teaching a child to learn from experience; that\'s how Machine Learning works. It\'s a journey from data to wisdom, from input to insight. And as we dive deeper into these concepts today, you\'ll see how they are the catalysts driving the AI revolution.',
    audio: '/media/audio/ai/2.mp3'
  },
  {
    img: '/media/img/3.png',
    caption: 'AI in Action',
    text: 'Now, let\'s see AI in action. Across industries — healthcare, finance, manufacturing — AI isn\'t just making an impact; it\'s reshaping the very fabric of these sectors. In healthcare, it\'s predictive diagnostics and personalized medicine. In finance, it\'s fraud detection and automated trading. In manufacturing, it\'s predictive maintenance and smart supply chains. These aren\'t just improvements; they\'re transformations, redefining how we approach challenges and solutions.',
    audio: '/media/audio/ai/3.mp3'
  },
  {
    img: '/media/img/4.png',
    caption: 'Seamless Integration',
    text: 'Integrating AI isn\'t about replacing the old with the new; it\'s about enhancing, augmenting, and elevating. It\'s a seamless blend where AI complements human expertise, making systems more efficient, reliable, and intelligent. Imagine a symphony, each instrument playing its part in harmony. That\'s what seamless integration looks like — different components, technologies, and people coming together to create a symphony of efficiency and innovation.',
    audio: '/media/audio/ai/4.mp3'
  },
  {
    img: '/media/img/5.png',
    caption: 'Real-World Impact',
    text: 'Let\'s talk impact. Real, tangible, measurable impact. It\'s one thing to discuss AI in theory, but it\'s another to see it change lives, industries, and economies. From small villages where AI-powered diagnostics are saving lives to large corporations where AI is streamlining operations and driving growth, the impact is profound and pervasive. And through case studies and real-world examples, we\'ll explore this impact in depth.',
    audio: '/media/audio/ai/5.mp3'
  },
  {
    img: '/media/img/6.png',
    caption: 'Integration Strategies',
    text: 'Integrating AI is both an art and a science. It requires a strategic approach, a roadmap that considers not just the technological aspects but also the human, ethical, and practical dimensions. Today, we\'ll discuss strategies that have been successful, the challenges faced, and the lessons learned. We\'ll delve into case studies, examining how different organizations have navigated their AI integration journey.',
    audio: '/media/audio/ai/6.mp3'
  },
  {
    img: '/media/img/7.png',
    caption: 'Global Impact',
    text: 'AI isn\'t just transforming individual lives or industries; it\'s reshaping the global landscape. It\'s a force that transcends borders, cultures, and economies. Its impact is universal, touching everything from global supply chains and international trade to climate change and global health crises. Today, we\'ll explore the breadth and depth of AI\'s global impact, understanding how it\'s not just changing the world but also creating a new one.',
    audio: '/media/audio/ai/7.mp3'
  },
  {
    img: '/media/img/8.png',
    caption: 'Ethical Considerations',
    text: 'With great power comes great responsibility. As we harness the power of AI, we must also address the ethical considerations. Fairness, accountability, transparency — these are not just ethical luxuries; they are fundamental necessities. Today, we\'ll discuss the ethical imperatives of AI, exploring how we can ensure that as we advance technologically, we also advance ethically, ensuring that AI benefits all of humanity.',
    audio: '/media/audio/ai/8.mp3'
  },
  {
    img: '/media/img/9.png',
    caption: 'The Future of AI',
    text: 'The future of AI is as exciting as it is uncertain. It\'s a canvas of possibilities, a horizon of opportunities. Today, we\'ll imagine that future — smart cities, intelligent transport, AI-powered healthcare, and more. We\'ll explore the trends, the emerging technologies, and the potential scenarios. And as we look towards this future, we\'ll also discuss how we can shape it, ensuring that it\'s a future that reflects our values, meets our needs, and fulfills our potential.',
    audio: '/media/audio/ai/9.mp3'
  },
  {
    img: '/media/img/10.png',
    caption: 'Conclusion',
    text: 'As we conclude, remember that this is just the beginning. The journey ahead is long, exciting, and full of opportunities. It\'s a journey we\'ll take together — as learners, leaders, and innovators. Thank you for embarking on this journey today. Let\'s continue to explore, learn, and shape the future. A future not just powered by AI, but a future powered by our collective imagination, determination, and humanity.',
    audio: '/media/audio/ai/10.mp3'
  }
  // Add more slides as needed
];

const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Explicitly defining the type of the `audioRefs` array
  const audioRefs = useRef<(HTMLAudioElement | null)[]>([]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
        prevSlide + 1 === slides.length ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 10000000); // Change slide every 10 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const audio = audioRefs.current[currentSlide];
    if (audio) {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
        }).catch(error => {
          console.error('Failed to play audio:', error);
        });
      }
    }
    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [currentSlide]);

  return (
      <div className="slideshow-container">
        {slides.map((slide, index) => (
            <div
                key={index}
                className={`slide ${index === currentSlide ? 'active' : ''}`}
            >
              <img src={slide.img} alt={`Slide ${index + 1}`} />
              <div className="caption">{slide.caption}</div>
              <div className="text-content">{slide.text}</div>
              <audio ref={el => (audioRefs.current[index] = el)} src={slide.audio}></audio>
            </div>
        ))}

        <button className="prev" onClick={prevSlide}>&#10094;</button>
        <button className="next" onClick={nextSlide}>&#10095;</button>
      </div>
  );
};

export default Slideshow;
