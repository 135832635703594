import React, {useEffect, useState} from 'react';

// Define a type for the buyer data
type Buyer = {
    address: string;
    amount: string; // Assuming amount is returned as a string from the API
};

const GetTopWETHBuyers: React.FC = () => {
    const [buyers, setBuyers] = useState<Buyer[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetch('https://skilltools.md/api7/getTopBuyers')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data: Buyer[]) => { // Assuming the data is directly an array of Buyer objects
                setBuyers(data);
                setIsLoading(false);
            })
            .catch((error: Error) => {
                console.error("Failed to fetch top WETH buyers:", error);
                setError(error.toString());
                setIsLoading(false);
            });
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h2>Top WETH Buyers</h2>
            <table>
                <thead>
                <tr>
                    <th>Address</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {buyers.map((buyer, index) => (
                    <tr key={index}>
                        <td>
                            <a href={buyer.address}>{buyer.address}</a>
                        </td>
                        <td>{buyer.amount}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default GetTopWETHBuyers;
