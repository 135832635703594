import React from 'react';
import './App.css';
import './Nav.css';
import SkillToolsComponent from "./skilltools1702739968/SkilltoolsComponent";
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import Component1 from "./skilltools1702739968/Component1";
import Component2 from "./skilltools1702740065/Component2";
import Component3 from "./skilltools1702740087/Component3";
import Component4 from "./skilltools1702740186/Component4";
import Component5 from "./skilltools1702740233/Component5";
import Component6 from "./skilltools1702740327/Component6";
import Component7 from "./skilltools1702740408/Component7";
import PreferencesForm from './PreferencesForm';
import Slideshow from './Slideshow';
import GetTopWETHBuyers from './Component/TopBuyers';
import Token from './Component/Token';


function App() {
    // Array of components in the order you want to render them
    const components = [
        Component7,
        Component6,
        SkillToolsComponent,
        Component5,
        Component4,
        Component3,
        Component2,
        Component1,
    ];

    // Get the current day (assuming it starts from 0)
    const currentDay = new Date().getDay();

    // Render the component for the current day
    const HomePage = components[currentDay];

    return (
        <Router>
            <div>
                <div className="cosmic-background"></div>
                <div className="cosmic-galaxy"></div>
                <div className="navbar">
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/apps">Apps</Link>
                        </li>
                        <li>
                            <Link to="/topBuyers">Top buyers</Link>
                        </li>
                        <li>
                            <Link to="/token">Token</Link>
                        </li>
                        
                        {/* Add other navigation links here */}
                    </ul>
                </div>

                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/about" element={<Slideshow/>}/>
                    <Route path="/apps" element={<PreferencesForm/>}/>
                    <Route path="/topBuyers" element={<GetTopWETHBuyers/>}/>
                    <Route path="/token" element={<Token/>}/>
                    {/* Add your other routes here */}
                </Routes>
            </div>
        </Router>

    );
}

export default App;
