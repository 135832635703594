import React, { useEffect, useState } from 'react';

// Define a type for the token data
type Token = {
    id: string;
    symbol: string;
    name: string;
    decimals: string;
    tradeVolumeUSD: string;
    untrackedVolumeUSD: string;
    totalLiquidity: string;
    totalSupply: string;
    tradeVolume: string;
};

// Define an enum for the sort fields
enum SortFields {
    ID = 'id',
    SYMBOL = 'symbol',
    NAME = 'name',
    DECIMALS = 'decimals',
    TRADE_VOLUME_USD = 'tradeVolumeUSD',
    UNTRACKED_VOLUME_USD = 'untrackedVolumeUSD',
    TOTAL_LIQUIDITY = 'totalLiquidity',
    TOTAL_SUPPLY = 'totalSupply',
    TRADE_VOLUME = 'tradeVolume',
}

const TokenListComponent: React.FC = () => {
    const [tokens, setTokens] = useState<Token[]>([]); // Change to an array of tokens
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [sortField, setSortField] = useState<SortFields>(SortFields.ID);
    const [isAscending, setIsAscending] = useState<boolean>(true);

    useEffect(() => {
        fetch('https://skilltools.md/api7/getTokenData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orderBy: sortField,
                orderDirection: isAscending ? 'asc' : 'desc',
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data: Token[]) => {
                setTokens(data);
                setIsLoading(false);
            })
            .catch((error: Error) => {
                console.error("Failed to fetch token data:", error);
                setError(error.toString());
                setIsLoading(false);
            });
    }, [sortField, isAscending]); 
    
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h2>Token Data</h2>
            <table>
                <thead>
                <tr>
                    <th onClick={() => { setSortField(SortFields.ID); setIsAscending(!isAscending); }}>
                        ID {sortField === SortFields.ID ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                    <th onClick={() => { setSortField(SortFields.SYMBOL); setIsAscending(!isAscending); }}>
                        Symbol {sortField === SortFields.SYMBOL ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                    <th onClick={() => { setSortField(SortFields.NAME); setIsAscending(!isAscending); }}>
                        Name {sortField === SortFields.NAME ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                    <th onClick={() => { setSortField(SortFields.DECIMALS); setIsAscending(!isAscending); }}>
                        Decimals {sortField === SortFields.DECIMALS ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                    <th onClick={() => { setSortField(SortFields.TRADE_VOLUME_USD); setIsAscending(!isAscending); }}>
                        Trade Volume USD {sortField === SortFields.TRADE_VOLUME_USD ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                    <th onClick={() => { setSortField(SortFields.UNTRACKED_VOLUME_USD); setIsAscending(!isAscending); }}>
                        Untracked Volume USD {sortField === SortFields.UNTRACKED_VOLUME_USD ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                    <th onClick={() => { setSortField(SortFields.TOTAL_LIQUIDITY); setIsAscending(!isAscending); }}>
                        Total Liquidity {sortField === SortFields.TOTAL_LIQUIDITY ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                    <th onClick={() => { setSortField(SortFields.TOTAL_SUPPLY); setIsAscending(!isAscending); }}>
                        Total Supply {sortField === SortFields.TOTAL_SUPPLY ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                    <th onClick={() => { setSortField(SortFields.TRADE_VOLUME); setIsAscending(!isAscending); }}>
                        Trade Volume {sortField === SortFields.TRADE_VOLUME ? (isAscending ? '↑' : '↓') : ''}
                    </th>
                </tr>
                </thead>
                <tbody>
                {tokens.map((token, index) => (
                    <tr key={index}>
                        <td>{token.id}</td>
                        <td>{token.symbol}</td>
                        <td>{token.name}</td>
                        <td>{token.decimals}</td>
                        <td>{token.tradeVolumeUSD}</td>
                        <td>{token.untrackedVolumeUSD}</td>
                        <td>{token.totalLiquidity}</td>
                        <td>{token.totalSupply}</td>
                        <td>{token.tradeVolume}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TokenListComponent;
