// SkillToolsComponent.tsx

import React from 'react';
import './skilltools1702740435.css'; // Import your custom styles here

const SkillToolsComponent: React.FC = () => {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>SkillTools</title>
        <meta
          name="description"
          content="ams. Gain the skills and knowledge needed to thrive in today's competitive workplace."
        />
        <meta name="author" content="Content Bot" />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"
          integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
          crossOrigin="anonymous"
        />
        {/*<link href='./skilltools1702740435.css' type="text/css" rel='stylesheet' />*/}
        <link rel='shortcut icon' type='image/jpg' href='https://www.gravatar.com/avatar/074d6be3b9fb54023b4ed3c6ed2ea2ad?d=identicon&f=y'/>
      </head>
      <body>
        <div className="navigation shadow-sm position-relative">
          <div className="row px-4">
            <div className="col-12 col-md-6 p-4">
              <img src="https://www.gravatar.com/avatar/074d6be3b9fb54023b4ed3c6ed2ea2ad?d=identicon&amp;f=y" alt="Logo" />
              <div data-name="brand" className="logo">SkillTools</div>
            </div>

          </div>
        </div>
        <div className="header-section bg-light">
          <div className="row">
            <div className="col-12 order-2 col-md-6 order-md-2">
              <div className="w-50 start-50 top-50 position-relative translate-middle py-4">
                <h1 data-name="title">Unlock Your True Potential with SkillTools</h1>
                <p data-name="copy">Develop new skills and gain knowledge to stay ahead in a competitive workplace with SkillTools. Our comprehensive training solutions help increase growth, productivity, and effectiveness for both individuals and businesses.</p>
              </div>
            </div>

            <div className="col-12 order-1 col-md-6 order-md-2">
              <img src="https://source.unsplash.com/random/1920x1080?" alt="Unlock Your True Potential with SkillTools" title="Unlock Your True Potential with SkillTools" className="init" />
            </div>
          </div>
        </div>
        <div className="sub-header-section py-4">
          <div className="row">
            <div className="col-12 col-md-6 py-4">
              <div className="row">
                <div className="col-8 m-auto card border-0 shadow p-0 overflow-hidden">
                  <img src="https://source.unsplash.com/random/1920x1080?" alt="Unleash the Power of Digital Learning" title="Unleash the Power of Digital Learning" className="init" />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="w-50 start-50 top-50 position-relative translate-middle">
                <h1 data-name="title">Unleash the Power of Digital Learning</h1>
                <p data-name="copy">SkillTools is an e-learning platform that provides tailored programs and resources so individuals and businesses can stay ahead. Our courses and workshops empower you to improve your employee's digital skills and prepare your business for success.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="features bg-light py-4">
          <div className="row py-4 mb-2">
            <div className="col-12 text-center">
              <h2>Features</h2>
            </div>
          </div>
          <div className="container">
            <div className="row item-container row-cols-1 row-cols-md-3">
              <div className="feature col">
                <div className="row text-center py-2">
                  <img src="https://source.unsplash.com/random/1920x1080?" alt="Comprehensive Program Library" title="Comprehensive Program Library" className="init" />
                  <h3 data-name="title" className="col-12 mt-4 mb-2">Comprehensive Program Library</h3>
                  <p data-name="desc" className="col-12">With over 500+ courses designed by experts, our wide selection of programs and solutions helps individuals and businesses become competent in the digital world.</p>
                </div>
              </div>
              <div className="feature col">
                <div className="row text-center py-2">
                  <img src="https://source.unsplash.com/random/1920x1080?" alt="Engaging User Interface" title="Engaging User Interface" className="init" />
                  <h3 data-name="title" className="col-12 mt-4 mb-2">Engaging User Interface</h3>
                  <p data-name="desc" className="col-12">SkillTools comes with a user-friendly interface that easily adapts and updates based on your company's unique needs.</p>
                </div>
              </div>
              <div className="feature col">
                <div className="row text-center py-2">
                  <img src="https://source.unsplash.com/random/1920x1080?" alt="24/7 Learning Resources" title="24/7 Learning Resources" className="init" />
                  <h3 data-name="title" className="col-12 mt-4 mb-2">24/7 Learning Resources</h3>
                  <p data-name="desc" className="col-12">We provide access to online resources and on-the-go support, so you can learn at your own pace and convenience.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="benefits py-4">
          <div className="container py-4 mt-4 mb-4">
            <div className="row item-container row-cols-1 row-cols-md-3">
              <div className="benefit col">
                <div className="row text-center py-2">
                  <div className="col-12">
                    <div className="icon-wrap">
                      <img className="icon init" src="https://www.gravatar.com/avatar/fa50ded9be1018fd4ee88c5fd8f49c6a?d=identicon&f=y" alt="Expert Instructors" title="Expert Instructors" />
                    </div>
                  </div>
                  <h2 data-name="title" className="col-12">Expert Instructors</h2>
                  <p data-name="desc" className="col-12">We have a team of well-experienced instructors who offer step-by-step guidance to help you reach your goals.</p>
                </div>
              </div>
              <div className="benefit col">
                <div className="row text-center py-2">
                  <div className="col-12">
                    <div className="icon-wrap">
                      <img className="icon init" src="https://www.gravatar.com/avatar/4c2ebd29260cca62ae109faffd97255e?d=identicon&f=y" alt="Personalized Training Solutions" title="Personalized Training Solutions" />
                    </div>
                  </div>
                  <h2 data-name="title" className="col-12">Personalized Training Solutions</h2>
                  <p data-name="desc" className="col-12">Our corporate training solutions are designed to meet the needs of your company and team. We provide tailored training programs to augment skill gaps and boost employee development.</p>
                </div>
              </div>
              <div className="benefit col">
                <div className="row text-center py-2">
                  <div className="col-12">
                    <div className="icon-wrap">
                      <img className="icon init" src="https://www.gravatar.com/avatar/c40ffa049fe9d6289999f8cd98910791?d=identicon&f=y" alt="Proven Results" title="Proven Results" />
                    </div>
                  </div>
                  <h2 data-name="title" className="col-12">Proven Results</h2>
                  <p data-name="desc" className="col-12">Our comprehensive learning</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer bg-dark text-light">
          <div className="row px-4 container m-auto">
            <div className="col-12 p-4 text-center">
              Copyright <span data-name="brand" className="copyright">SkillTools</span> <span data-name="year">2023</span>
              <br />
              <br />
             </div>
          </div>
        </div>
      </body>
    </html>
  );
};

export default SkillToolsComponent;
