import React from 'react';

// Define an interface for your course data
interface Course {
  title: string;
  description: string;
  url: string;
}

interface State {
  interests: string;
  goals: string;
  experience: string;
  courseRecommendation: Course | null; // Use the Course interface or null
}

class PreferencesForm extends React.Component {

  state: State = {
    interests: 'Cooking cookies',
    goals: 'Take digital marketing skills',
    experience: '0 year',
    courseRecommendation: null, // Initially null, but will be a Course object
  };

  handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async () => {
    const payload = {
      interests: this.state.interests,
      goals: this.state.goals,
      experience: this.state.experience,
    };

    try {
      const response = await fetch('https://skilltools.md/api7/getPreferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response data:", data);

      // Update the state with the course recommendation
      this.setState({ courseRecommendation: data.courses });

    } catch (error) {
      console.error("Error making the HTTP request:", error);
    }
  };

  renderCourseRecommendation = () => {
    const { courseRecommendation } = this.state;
    if (!courseRecommendation) return null; // If no recommendation, don't render anything

    return (
        <div className="course-recommendation">
          <h3>{courseRecommendation.title}</h3>
          <p>{courseRecommendation.description}</p>
          <p>{courseRecommendation.url} </p>
        </div>
    );
  };

  render() {
    return (
        <div className="preferences-form">
          <h1>User Preferences</h1>
          <div className="form-group">
            <label>Interests</label>
            <input
                type="text"
                name="interests"
                value={this.state.interests}
                onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Goals</label>
            <input
                type="text"
                name="goals"
                value={this.state.goals}
                onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Experience</label>
            <input
                type="text"
                name="experience"
                value={this.state.experience}
                onChange={this.handleInputChange}
            />
          </div>
          <button onClick={this.handleSubmit} className="submit-btn">Send</button>

          {/* Render the course recommendation if it exists */}
          {this.renderCourseRecommendation()}
        </div>
    );
  }
}

export default PreferencesForm;
