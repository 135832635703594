import React from 'react';
import './skilltools1702740186.css'; // Import your custom styles here

const Component4: React.FC = () => {
    return (
        <>
            <head>
                <title>SkillTools</title>
                <meta
                    name="description"
                    content="ams. Gain the skills and knowledge needed to thrive in today's competitive workplace. Corporate Training Solutions: SkillTools partners with businesses to provide tailored training solutions that enhance the skill set of their workforce. Invest in your team's growth with our corporate training programs. Join SkillTools Today: Embark on a journey of continuous learning and growth with SkillTools. Whether you're an individual looking to upskill or a company seeking comprehensive training solutions, SkillTools is your partner in the pursuit of excellence. Unlock Your Potential with SkillTools."
                />
                <meta name="author" content="Content Bot" />
                <meta name="charset" content="utf-8" />
                <link
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"
                    rel="stylesheet"
                    integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
                    crossOrigin="anonymous"
                />
                <link href='skilltools1702740186.css' rel='stylesheet' />
                <link
                    rel='shortcut icon'
                    type='image/jpg'
                    href='https://www.gravatar.com/avatar/074d6be3b9fb54023b4ed3c6ed2ea2ad?d=identicon&f=y'
                />
            </head>
            <body>
            <div className="navigation shadow-sm position-relative">
                <div className="row px-4">
                    <div className="col-12 col-md-6 p-4">
                        <img src="https://www.gravatar.com/avatar/074d6be3b9fb54023b4ed3c6ed2ea2ad?d=identicon&amp;f=y" alt="SkillTools" />
                        <div data-name="brand" className="logo">
                            SkillTools
                        </div>
                    </div>

                </div>
            </div>
            <div className="header-section bg-light">
                <div className="row">
                    <div className="col-12 order-2 col-md-6 order-md-2">
                        <div className="w-50 start-50 top-50 position-relative translate-middle py-4">
                            <h1 data-name="title">Unlock Your Professional Potential With SkillTools</h1>
                            <p data-name="copy">
                                SkillTools provides tailored corporate training solutions and online learning resources to help individuals and businesses develop the skills they need to succeed.
                            </p>
                        </div>
                    </div>

                    <div className="col-12 order-1 col-md-6 order-md-2">
                        <img
                            src="https://source.unsplash.com/random/1920x1080?"
                            alt="Unlock Your Professional Potential With SkillTools"
                            title="Unlock Your Professional Potential With SkillTools"
                            className="init"
                        />
                    </div>
                </div>
            </div>
            <div className="sub-header-section py-4">
                <div className="row">
                    <div className="col-12 col-md-6 py-4">
                        <div className="row">
                            <div className="col-8 m-auto card border-0 shadow p-0 overflow-hidden">
                                <img
                                    src="https://source.unsplash.com/random/1920x1080?"
                                    alt="Invest in your Team's Growth & Bleed-edge Skills"
                                    title="Invest in your Team's Growth & Bleed-edge Skills"
                                    className="init"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="w-50 start-50 top-50 position-relative translate-middle">
                            <h1 data-name="title">Invest in your Team's Growth & Bleed-edge Skills</h1>
                            <p data-name="copy">
                                Become an expert in corporate training and cutting edge digital skills with SkillTools. With our comprehensive e-learning platform, you can take your knowledge to the next level with our range of courses and workshops.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features bg-light py-4">
                <div className="row py-4 mb-2">
                    <div className="col-12 text-center">
                        <h2>Features</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row item-container row-cols-1 row-cols-md-3">
                        <div className="feature col">
                            <div className="row text-center py-2">
                                <img
                                    src="https://source.unsplash.com/random/1920x1080?"
                                    alt="Customizable Corporate Training Solutions"
                                    title="Customizable Corporate Training Solutions"
                                    className="init"
                                />
                                <h3 data-name="title" className="col-12 mt-4 mb-2">
                                    Customizable Corporate Training Solutions
                                </h3>
                                <p data-name="desc" className="col-12">
                                    We work with businesses to create tailored corporate training solutions that equip their workforce with the skill set needed to optimize performance and efficiency.
                                </p>
                            </div>
                        </div>
                        <div className="feature col">
                            <div className="row text-center py-2">
                                <img
                                    src="https://source.unsplash.com/random/1920x1080?"
                                    alt="Online Learning Resources"
                                    title="Online Learning Resources"
                                    className="init"
                                />
                                <h3 data-name="title" className="col-12 mt-4 mb-2">
                                    Online Learning Resources
                                </h3>
                                <p data-name="desc" className="col-12">
                                    Our library of online learning resources and courses are designed to give individuals an opportunity to upskill and explore new digital skills at their own pace.
                                </p>
                            </div>
                        </div>
                        <div className="feature col">
                            <div className="row text-center py-2">
                                <img
                                    src="https://source.unsplash.com/random/1920x1080?"
                                    alt="Expert Instructors"
                                    title="Expert Instructors"
                                    className="init"
                                />
                                <h3 data-name="title" className="col-12 mt-4 mb-2">
                                    Expert Instructors
                                </h3>
                                <p data-name="desc" className="col-12">
                                    Our instructors are experts in their field and provide guidance and support throughout the learning journey.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="benefits py-4">
                <div className="container py-4 mt-4 mb-4">
                    <div className="row item-container row-cols-1 row-cols-md-3">
                        <div className="benefit col">
                            <div className="row text-center py-2">
                                <div className="col-12">
                                    <div className="icon-wrap">
                                        <img
                                            className="icon init"
                                            src="https://www.gravatar.com/avatar/9b3dbfae0ae2eeb257a2399613fc8274?d=identicon&amp;f=y"
                                            alt="Professional Development"
                                            title="Professional Development"
                                        />
                                    </div>
                                </div>
                                <h2 data-name="title" className="col-12">
                                    Professional Development
                                </h2>
                                <p data-name="desc" className="col-12">
                                    SkillTools provides the tools and resources needed for individuals and businesses to make a successful transition into the workplace.
                                </p>
                            </div>
                        </div>
                        <div className="benefit col">
                            <div className="row text-center py-2">
                                <div className="col-12">
                                    <div className="icon-wrap">
                                        <img
                                            className="icon init"
                                            src="https://www.gravatar.com/avatar/ab4bf08c17fbf6a566b8f50abf209f01?d=identicon&amp;f=y"
                                            alt="Bleeding Edge Skills"
                                            title="Bleeding Edge Skills"
                                        />
                                    </div>
                                </div>
                                <h2 data-name="title" className="col-12">
                                    Bleeding Edge Skills
                                </h2>
                                <p data-name="desc" className="col-12">
                                    Unlock new digital skills with SkillTools and stay ahead of the competition with up-to-date knowledge and insight.
                                </p>
                            </div>
                        </div>
                        <div className="benefit col">
                            <div className="row text-center py-2">
                                <div className="col-12">
                                    <div className="icon-wrap">
                                        <img
                                            className="icon init"
                                            src="https://www.gravatar.com/avatar/476a8eba7013ebd78c8549bebc373193?d=identicon&amp;f=y"
                                            alt="Tailored Corporate Training"
                                            title="Tailored Corporate Training"
                                        />
                                    </div>
                                </div>
                                <h2 data-name="title" className="col-12">
                                    Tailored Corporate Training
                                </h2>
                                <p data-name="desc" className="col-12">
                                    Our tailored corporate training solutions cater specifically to the needs of businesses and equip their workforce with the skills and knowledge they need to excel.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer bg-dark text-light">
                <div className="row px-4 container m-auto">
                    <div className="col-12 p-4 text-center">
                        Copyright <span data-name="brand" className="copyright">SkillTools</span> <span data-name="year">2023</span>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            </body>
        </>
    );
};

export default Component4;
